import { combineReducers } from 'redux';
import menuReducer from './menu';
import reducerPedido from './pedido';
import reducerCategorias from './categorias';

const reducers = combineReducers({
  menu: menuReducer,
  pedido: reducerPedido,
  categorias: reducerCategorias,
});

export default reducers;
