import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { IonSlides } from '@ionic/react';
import { TypeCategoria, TypeMenu, TypePlato, TypeState } from '../utils/Types';
import Plato from './Plato';

const Wrapper = styled.div`
  position: relative;
`;

const StyledSlides = styled(IonSlides)`
  padding: 0;
  --bullet-background: white;
  --bullet-background-active: #78b657;
`;

const Tag = styled.div`
  background: rgba(255, 255, 255, 0.9);
  max-height: 300px;
  height: 40%;
  width: 30px;
  position: absolute;
  top: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
`;

const Title = styled.h2`
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 24px;
  transform: rotate(-90deg);
  position: absolute;
  width: 250px;
  text-align: center;
  color: black;
`;

const Divider = styled.div`
  width: 90%;
  margin: 2rem auto;
  height: 2px;
  background: white;
`;

interface Props {
  categoria: TypeCategoria;
  menu: TypeMenu;
  title: string;
  cardSize: 'S' | 'M' | 'L';
  ref?: React.MutableRefObject<null>;
  id: string;
}

const ListaPlatos: React.FC<Props> = ({
  menu,
  categoria,
  title,
  cardSize,
  ref,
  id,
}) => {
  const [platos, setPlatos] = useState<TypePlato[]>([]);

  const slideOpts = {
    slidesPerView: 1.2,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
  };
  useEffect(() => {
    setPlatos(menu[categoria]);
  }, [categoria, menu]);

  const mappedPlatos = platos.map(plato => {
    return (
      <Plato
        key={plato.plato}
        plato={plato.plato}
        desc={plato.desc}
        precio={plato.precio}
        img={plato.img}
        cardSize={cardSize}
      />
    );
  });

  return (
    <Wrapper id={id}>
      <Tag>
        <Title>{title}</Title>
      </Tag>
      <StyledSlides pager={true} options={slideOpts}>
        {mappedPlatos}
      </StyledSlides>
      <Divider />
    </Wrapper>
  );
};

const mapStateToProps = (state: TypeState) => ({
  menu: state.menu,
});
export default connect(mapStateToProps)(ListaPlatos);
