import React from 'react';
import styled from 'styled-components';

const StyledH1 = styled.h1`
  &.h1 {
    font-family: 'Raleway', sans-serif;
    font-weight: 900;
    color: white;
    font-size: 20px;
  }
`;

interface Props {
  className?: string;
  children: React.ReactChild;
}

const H1: React.FC<Props> = ({ className, children }) => {
  return (
    <StyledH1 className={`h1 ${className ? className : ''}`}>
      {children}
    </StyledH1>
  );
};

export default H1;
