import React from 'react';
import { IonSlide } from '@ionic/react';
import styled from 'styled-components';
import H1 from './common/H1';
import P from './common/P';

interface WrapperProps {
  cardSize: 'S' | 'M' | 'L';
}

const Wrapper = styled.div<WrapperProps>`
  &.wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 1.25rem;
    background: rgba(0, 0, 0, 0.45);
    margin: 0 1rem 3rem;
    border-radius: 2rem;
    box-shadow: 4px 4px 6px #000000;
    height: 500px;
    min-height: 500px;
    max-height: 500px;
  }
`;

const Img = styled.img`
  &.img-plato {
    border-radius: 1rem;
    max-height: 220px;
  }
`;

const TextWrapper = styled.div``;

interface Props {
  plato: string;
  desc: string | string[];
  precio: string;
  img: string;
  cardSize: 'S' | 'M' | 'L';
}

const Plato: React.FC<Props> = ({ plato, desc, precio, img, cardSize }) => {
  const mapDesc = (arr: string[]) => arr.map((element, i) => <P key={i}>{element}</P>)

  return (
    <IonSlide>
      <Wrapper className="wrapper" cardSize={cardSize}>
        <TextWrapper>
          <Img className="img-plato" src={img} alt="" />
          <H1>{plato}</H1>
          {typeof desc === 'string' ? <P>{desc}</P> : mapDesc(desc)}
        </TextWrapper>
        <P>{precio}</P>
      </Wrapper>
    </IonSlide>
  );
};

export default Plato;
