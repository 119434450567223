import React, { useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonLoading,
} from '@ionic/react';
import styled from 'styled-components';
import './Home.css';
import Logo from '../components/Logo';
import BGWood from './../imgs/wood-bg1.jpg';
import Platos from '../components/Platos';

interface Props {}

const StyledIonToolbar = styled(IonToolbar)`
  --background: url(${BGWood}) no-repeat 100% / 100%;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.8);
`;

const StyledBody = styled(IonContent)``;
const Home: React.FC<Props> = () => {
  const [showLoading, setShowLoading] = useState(true);
  return (
    <IonPage>
      <IonContent fullscreen>
        <IonHeader>
          <StyledIonToolbar>
            <Logo />
          </StyledIonToolbar>
        </IonHeader>
        <StyledBody>
          {showLoading && (
            <IonLoading
              cssClass="my-custom-class"
              isOpen={showLoading}
              onDidDismiss={() => setShowLoading(false)}
              message={'Cargando...'}
              duration={3000}
            />
          )}
          <Platos />
        </StyledBody>
      </IonContent>
    </IonPage>
  );
};

export default Home;
