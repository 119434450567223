import menu from '../menu.json';
import { TypeAction } from '../utils/Types';

const initialState = {
  ...menu.menu.platos,
};

const menuReducer = (state = initialState, { type, payload }: TypeAction) => {
  switch (type) {
    default:
      return state;
  }
};

export default menuReducer;
