import { TypeAction } from '../utils/Types';
const initialStore = {
  plato: 'algo',
};

const reducerPedido = (state = initialStore, { type, payload }: TypeAction) => {
  switch (type) {
    default:
      return state;
  }
};

export default reducerPedido;
