import React from 'react';
import styled from 'styled-components';

const StyledP = styled.p`
  &.p {
    color: white;
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
  }
`;

interface Props {
  className?: string;
  children: React.ReactChild;
}

const P: React.FC<Props> = ({ className, children }) => {
  return (
    <StyledP className={`p ${className ? className : ''}`}>{children}</StyledP>
  );
};

export default P;
