import { IonContent } from '@ionic/react';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Categorias from './Categorias';
import ListaPlatos from './ListaPlatos';
import BG from './../imgs/bg1.jpg';
import { connect } from 'react-redux';
import { TypeState } from '../utils/Types';

const StyledContent = styled(IonContent)`
  &.platos-wrapper {
    --background: url(${BG}) center / cover no-repeat;
    max-height: 100%;
    position: absolute;
  }
`;

const PlatosWrapper = styled.div`
  margin-top: 90px;
`;

const LastSpacer = styled.div`
  height: 100px;
`;

interface Props {
  selected: string;
}

const Platos: React.FC<Props> = ({ selected }) => {
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  useEffect(() => {
    const getY = (cat: string) => {
      switch (cat) {
        case 'entradas':
          return 0;
        case 'burguers':
          return 610;
        case 'pastas':
          return 1220;
        case 'chivitos':
          return 1840;
        case 'ensaladas':
          return 2450;
        case 'asado':
          return 3070;
        case 'milangas':
          return 3680;
        case 'delMar':
          return 4290;
        case 'pizzas':
          return 4910;
        case 'infantil':
          return 5520;
        case 'postres':
          return 6140;
        case 'bebidas':
          return 6750;
        case 'cervezas':
          return 7360;
        case 'tragos':
          return 7970;
          case 'vinos':
          return 8580;
      }
    };
    contentRef.current &&
      contentRef.current.scrollToPoint(0, getY(selected), 1000);
  }, [selected]);

  return (
    <StyledContent
      className="platos-wrapper"
      ref={contentRef}
      scrollEvents
      fullscreen
    >
      <Categorias />
      <PlatosWrapper>
        <ListaPlatos
          categoria="entradas"
          title="ENTRADAS"
          cardSize="M"
          id="entradas"
        />
        <ListaPlatos
          categoria="burgers"
          title="BURGUERS"
          cardSize="M"
          id="burgers"
        />
        <ListaPlatos
          categoria="pastas"
          title="PASTAS"
          cardSize="M"
          id="pastas"
        />
        <ListaPlatos
          categoria="chivitos"
          title="CHIVITOS"
          cardSize="M"
          id="chivitos"
        />
        <ListaPlatos
          categoria="ensaladas"
          title="ENSALADAS"
          cardSize="M"
          id="ensaldas"
        />
        <ListaPlatos categoria="asado" title="ASADO" cardSize="M" id="asado" />
        <ListaPlatos
          categoria="milangas"
          title="MILANGAS"
          cardSize="M"
          id="milangas"
        />
        <ListaPlatos
          categoria="delMar"
          title="DEL MAR"
          cardSize="M"
          id="delMar"
        />
        <ListaPlatos
          categoria="pizzas"
          title="PIZZAS"
          cardSize="S"
          id="pizzas"
        />

        <ListaPlatos
          categoria="menuInfantil"
          title="INFANTIL"
          cardSize="M"
          id="infantil"
        />

        <ListaPlatos
          categoria="postres"
          title="POSTRES"
          cardSize="M"
          id="postres"
        />
        <ListaPlatos
          categoria="bebidas"
          title="BEBIDAS"
          cardSize="S"
          id="bebidas"
        />
        <ListaPlatos
          categoria="cervezas"
          title="CERVEZAS"
          cardSize="S"
          id="cervezas"
        />
        <ListaPlatos
          categoria="tragos"
          title="TRAGOS"
          cardSize="S"
          id="tragos"
        />
        <ListaPlatos
          categoria="vinos"
          title="VINOS"
          cardSize="S"
          id="vinos"
        />
        <LastSpacer />
      </PlatosWrapper>
    </StyledContent>
  );
};

const mapStateToProps = ({ categorias }: TypeState) => ({
  selected: categorias.categoriaSeleccionada,
});
export default connect(mapStateToProps)(Platos);
