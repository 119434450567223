const CATEGORIA_SELECCIONADA = 'CATEGORIA_SELECCIONADA';

const initialState = {
  categoriaSeleccionada: 'entradas',
};

export const updateCategoriaSeleccionada = (cat: string) => ({
  type: CATEGORIA_SELECCIONADA,
  payload: cat,
});

type Action = {
  type: string;
  payload: any;
};

const categoriasReducer = (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case CATEGORIA_SELECCIONADA: {
      return {
        ...state,
        categoriaSeleccionada: payload,
      };
    }
    default:
      return state;
  }
};

export default categoriasReducer;
