import React from 'react';
import { IonSegment, IonSegmentButton } from '@ionic/react';
import menu from '../menu.json';

import { connect } from 'react-redux';
import { updateCategoriaSeleccionada } from '../store/categorias';
import styled from 'styled-components';
import BG from './../imgs/bg.jpg';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps<any> {
  updateCategoria: (cat: string) => void;
}

const Segment = styled(IonSegment)`
  position: relative;
  z-index: 1;
  // background: rgba(0, 0, 0, 0.5) url(${BG}) center / cover no-repeat;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(${BG});
  background-size: cover;
  border-radius: 0;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
`;

const StyledSegmentButton = styled(IonSegmentButton)`
  font-size: 16px;
  font-family: 'Raleway', sans-serif;
  --padding-top: 16px;
  --padding-bottom: 16px;
  --padding-start: 26px;
  --padding-end: 26px;
  margin: 8px;
  font-weight: 700;
  text-transform: uppercase;
  min-width: 110px;
  --indicator-color: #78b657;
  color: white;
`;

const Categorias: React.FC<Props> = ({ updateCategoria, history }) => {
  const handleChange = (cat: string | undefined) => {
    updateCategoria(cat || '');
  };

  const mappedCategorias = menu.menu.categorias.map(cat => {
    return (
      <StyledSegmentButton key={cat.value} value={cat.value}>
        {cat.display}
      </StyledSegmentButton>
    );
  });
  return (
    <Segment
      onIonChange={e => handleChange(e.detail.value)}
      scrollable
      slot="fixed"
    >
      {mappedCategorias}
    </Segment>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  updateCategoria: (cat: string) => dispatch(updateCategoriaSeleccionada(cat)),
});
export default connect(null, mapDispatchToProps)(withRouter(Categorias));
