import React from 'react';
import styled from 'styled-components';
import { IonImg } from '@ionic/react';
import LogoMojito from './../imgs/logo.svg';

const StyledImg = styled(IonImg)`
  &.logo {
    width: 70px;
    margin: 0 auto;
    padding: 1rem 0;
  }
`;

interface Props {}

const Logo: React.FC<Props> = () => {
  return <StyledImg src={LogoMojito} className="logo" />;
};

export default Logo;
